<template>
    <v-card>
        <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <!-- Create/Edit Behavior-->
        <v-card-text>
            <v-form v-model="isValid">
                <v-container class="py-0">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="editedItem.name.value"
                                class="purple-input mb-2"
                                :label="$t('common.name')"
                                :error-messages="editedItem.name.error"
                                :rules="[rules.required]"
                                :disabled="isOnlyView"
                                @keyup="editedItem.name.error = ''"
                                hide-details
                            />
                        </v-col>

                        <v-col
                            v-for="(langData, langCode) in i18nMapComponents"
                            :key="langCode"
                            cols="12"
                        >
                            <h6
                                v-text="langData.nameLang"
                                class="primary mb-0"
                            />

                            <v-text-field
                                v-model="langData.name"
                                class="purple-input mb-2"
                                :label="$t('common.name')"
                                :disabled="isOnlyView"
                                hide-details
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <div class="d-flex flex-wrap justify-space-around w-full">
                            <!-- <v-text-field
                                style="max-width: 80px"
                                v-model="editedItem.order.value"
                                class="purple-input"
                                :label="$t('admin.cards.order')"
                                :error-messages="editedItem.order.error"
                                :disabled="isOnlyView"
                                @keyup="editedItem.order.error = ''"
                            /> -->
                        </div>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-spacer />

            <v-btn
                color="blue darken-1"
                text
                @click="$router.go(-1)"
            >
                {{ $t(`common.${isOnlyView ? 'endProcess' : 'confirmDialogCancel'}`) }}
            </v-btn>
            <v-btn
                v-if="!isOnlyView"
                color="blue darken-1"
                text
                :disabled="!isValid && !serverCheck"
                @click="save"
            >
                {{ $t('common.dialogSave') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import axios from '@/plugins/axios';
import rules from '@/helpers/rulesValidate';
import { mapGetters, mapActions } from 'vuex';
import EvaluationsAPIService from '@/services/EvaluationsAPIService';

const DEFAULT_LANG = process.env.VUE_APP_DEFAULT_LANGUAGE;

export default {
    data: function () {
        return {
            gameId: null,
            rules: {
                ...rules
            },
            item: null,
            editedItem: this.getEditedItem(),
            isValid: true,
            serverCheck: false,
            isOnlyView: false,
            behaviorsI18nMapByLang: {},
            i18nMapComponents: {}
        };
    },
    computed: {
        ...mapGetters('languages', ['getLang', 'getLanguages']),

        formTitle() {
            return this.$tc('evaluation.behavior');
            /* if (this.item) {
                return this.isOnlyView ? this.$t('admin.cards.view') : this.$t('admin.cards.edit');
            } else return this.$t('admin.cards.new'); */
        }
    },
    async created() {
        await this.fetchLanguages();
    },

    async mounted() {
        const itemId = this.$route.params.id;
        const itemParam = this.$route.params.item;
        this.item = itemParam ?? itemId ? { id: itemId } : null;

        await this.fetchBehavior({ reloadAll: !itemParam });

        await this.createI18nMapComponents();
    },

    methods: {
        ...mapActions('languages', ['fetchLanguages']),

        getEditedItem(itemInit = null) {
            const item = itemInit ?? this.$route.params.item ?? null;

            var empty = !item;

            return {
                id: empty ? '' : item.id,
                name: {
                    value: empty ? '' : item.name,
                    error: ''
                }
                /* order: {
                    value: empty ? '' : +item.order,
                    error: ''
                }, */
            };
        },

        initialize() {},

        async save() {
            const formData = new FormData();

            formData.append('name', this.editedItem.name.value ?? '');

            // formData.append('active', this.editedItem.active.value ? 1 : 0);
            // if (this.editedItem.order.value) formData.append('order', this.editedItem.order.value);

            const i18n = Object.entries(this.i18nMapComponents).map(itemI18n => {
                const language = itemI18n[0];
                const { name } = itemI18n[1];

                return {
                    language,
                    name
                };
            });
            formData.append('i18n', JSON.stringify(i18n));

            let urlAppend = '';
            let method = 'POST';
            if (this.editedItem.id) {
                urlAppend = '/' + this.editedItem.id;
                method = 'PATCH';

                formData.append('_method', method);
            }

            try {
                const result = await EvaluationsAPIService.updateBehaviorForAdmin({ urlAppend, formData });

                if (result.status === 200 || result.status === 201 || result.status === 204) {
                    this.afterSave(result.data.behavior);

                    const actionMSG = this.editedItem.id ? 'updatedOK' : 'createdOK';
                    this.$store.dispatch('snackbar/success', this.$t('common.' + actionMSG));
                }
            } catch (error) {
                if (error?.response?.status === 422) {
                    if (error.response?.data?.errors?.length) {
                        error.response.data.errors.forEach(e => {
                            if (e.source.pointer === '/data/attributes/title') {
                                this.editedItem.title.error = e.detail;
                            }
                            if (e.source.pointer === '/data/attributes/body') {
                                this.editedItem.body.error = e.detail;
                            }
                        });
                    }
                }

                this.$store.dispatch('snackbar/error', this.$t('common.accionError'));
            }
        },
        afterSave(item) {
            this.isOnlyView = true;

            this.editedItem = this.getEditedItem(item);
        },

        async fetchBehavior({ reloadAll }) {
            try {
                const id = this.item?.id;
                if (id || id === 0) {
                    const result = await EvaluationsAPIService.getBehaviorForAdmin({ id });

                    if (reloadAll) {
                        this.item = result.data.behavior;
                        this.editedItem = this.getEditedItem(this.item);
                    }

                    const behaviorsI18n = result.data.behaviorsI18n;

                    this.behaviorsI18nMapByLang = behaviorsI18n.reduce((result, i18n) => {
                        result[i18n.language_id] = i18n;

                        return result;
                    }, {});
                }
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'));
            }
        },

        async createI18nMapComponents() {
            this.getLanguages?.forEach(lang => {
                if (DEFAULT_LANG !== lang.code) {
                    const i18n = this.behaviorsI18nMapByLang?.[lang.code] ?? {};
                    const langData = {
                        nameLang: lang.name,
                        ...i18n
                    };
                    this.$set(this.i18nMapComponents, lang.code, langData);
                }
            });
        }
    }
};
</script>

<style></style>
